import request from '@/utils/request'


export function fetchList(query) {
  return request({
    url: 'accounts',
    method: 'get',
    params: query
  })
}

export function fetchListForBroker(query) {
  return request({
    url: 'accounts',
    method: 'get',
    params: query
  })
}
export function downloadAcountList(query) {
  return request({
    url: '/accounts/download',
    method: 'get',
    params: query
  })
}
